import { createAction } from 'redux-actions'
import {
  FETCH_CONNECTIONS_REQUEST,
  FETCH_CONNECTIONS_FULFILLED,
  FETCH_CONNECTIONS_ERRED,
  SET_SELECTED_CONNECTION,
  SET_HOVERED_CONNECTION,
  SPLIT_CONNECTION,
  SPLIT_CONNECTION_FULFILLED,
  SPLIT_CONNECTION_ERRED,
  CONNECT_WAYPOINTS,
  CONNECT_WAYPOINTS_FULFILLED,
  CONNECT_WAYPOINTS_ERRED,
  DELETE_CONNECTION,
  DELETE_CONNECTION_FULFILLED,
  DELETE_CONNECTION_ERRED,
} from './connections.types'

export const fetchConnections = createAction(FETCH_CONNECTIONS_REQUEST)
export const fetchConnectionsFulfilled = createAction(FETCH_CONNECTIONS_FULFILLED)
export const fetchConnectionsErred = createAction(FETCH_CONNECTIONS_ERRED)
export const setSelectedConnection = createAction(SET_SELECTED_CONNECTION)
export const setHoveredConnection = createAction(SET_HOVERED_CONNECTION)
export const splitConnection = createAction(SPLIT_CONNECTION)
export const splitConnectionFulfilled = createAction(SPLIT_CONNECTION_FULFILLED)
export const splitConnectionErred = createAction(SPLIT_CONNECTION_ERRED)
export const connectWaypoints = createAction(CONNECT_WAYPOINTS)
export const connectWaypointsFulfilled = createAction(CONNECT_WAYPOINTS_FULFILLED)
export const connectWaypointsErred = createAction(CONNECT_WAYPOINTS_ERRED)
export const deleteConnection = createAction(DELETE_CONNECTION)
export const deleteConnectionFulfilled = createAction(DELETE_CONNECTION_FULFILLED)
export const deleteConnectionErred = createAction(DELETE_CONNECTION_ERRED)
