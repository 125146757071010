export const FETCH_CONNECTIONS_REQUEST = 'FETCH_CONNECTIONS_REQUEST'
export const FETCH_CONNECTIONS_FULFILLED = 'FETCH_CONNECTIONS_FULFILLED'
export const FETCH_CONNECTIONS_ERRED = 'FETCH_CONNECTIONS_ERRED'
export const SET_SELECTED_CONNECTION = 'SET_SELECTED_CONNECTION'
export const SET_HOVERED_CONNECTION = 'SET_HOVERED_CONNECTION'
export const SPLIT_CONNECTION = 'SPLIT_CONNECTION'
export const SPLIT_CONNECTION_FULFILLED = 'SPLIT_CONNECTION_FULFILLED'
export const SPLIT_CONNECTION_ERRED = 'SPLIT_CONNECTION_ERRED'
export const CONNECT_WAYPOINTS = 'CONNECT_WAYPOINTS'
export const CONNECT_WAYPOINTS_FULFILLED = 'CONNECT_WAYPOINTS_FULFILLED'
export const CONNECT_WAYPOINTS_ERRED = 'CONNECT_WAYPOINTS_ERRED'
export const DELETE_CONNECTION = 'DELETE_CONNECTION'
export const DELETE_CONNECTION_FULFILLED = 'DELETE_CONNECTION_FULFILLED'
export const DELETE_CONNECTION_ERRED = 'DELETE_CONNECTION_ERRED'
