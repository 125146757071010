import React from 'react'
import { connect } from 'react-redux'
import { List, ListItem, ListItemText } from '@material-ui/core'
import { setSelectedVenue } from './venues.actions'

const styles = {
  container: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
    width: 50,
    maxHeight: '80vh',
    overflowY: 'auto',
  },
  listItem: {
    paddingRight: 0,
    textAlign: 'center',
  },
}

const VenueSelection = ({ venues, selectedVenue, setSelectedVenue }) =>
  <div style={styles.container}>
    <List disablePadding dense>
      {venues.map(venueResponse =>
        <ListItem key={venueResponse.venue.id} button
                  style={styles.listItem}
                  selected={selectedVenue && selectedVenue.venue.id === venueResponse.venue.id}
                  onClick={() => setSelectedVenue(venueResponse)}>
          <ListItemText primary={venueResponse.venue.number}/>
        </ListItem>,
      )}
    </List>
  </div>

export default connect(store => ({
  venues: store.venues.items.sort((a, b) => +a.venue.number < +b.venue.number ? 1 : -1),
  selectedVenue: store.venues.selected,
}), { setSelectedVenue })(VenueSelection)
