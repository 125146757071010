import { createAction } from 'redux-actions'
import {
  FETCH_VENUES_REQUEST,
  FETCH_VENUES_FULFILLED,
  FETCH_VENUES_ERRED,
  SET_SELECTED_VENUE,
  SELECT_NEAREST_VENUE,
} from './venues.types'

export const fetchVenues = createAction(FETCH_VENUES_REQUEST)
export const fetchVenuesFulfilled = createAction(FETCH_VENUES_FULFILLED)
export const fetchVenuesErred = createAction(FETCH_VENUES_ERRED)
export const setSelectedVenue = createAction(SET_SELECTED_VENUE)
export const selectNearestVenue = createAction(SELECT_NEAREST_VENUE)
