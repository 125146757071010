import { get, post, delete as del } from 'axios/index'
import { baseUrl } from '../../constants/Config'

export const getServicesByRoom = roomId =>
  get(`${baseUrl}/getServicesByRoom`, { params: { roomId } })
    .then(response => response.data)

export const createService = (service, roomId) =>
  post(`${baseUrl}/createService`, { service, roomId })
    .then(response => response.data)

export const deleteServiceById = id =>
  del(`${baseUrl}/deleteServiceById`, { params: { id } })
    .then(response => response.data)
