import { of, from } from 'rxjs/index'
import { flatMap, switchMap, catchError } from 'rxjs/operators'
import { ofType, combineEpics } from 'redux-observable'
import { getVenuesByBuilding } from './venues.service'
import { setSelectedVenue, fetchVenuesFulfilled, fetchVenuesErred } from './venues.actions'
import { SET_SELECTED_BUILDING } from '../buildings/buildings.types'

const resetVenuesEpic = action$ =>
  action$
    .pipe(
      ofType(SET_SELECTED_BUILDING),
      flatMap(() => [fetchVenuesFulfilled([]), setSelectedVenue(null)]),
    )

const fetchVenuesEpic = action$ =>
  action$
    .pipe(
      ofType(SET_SELECTED_BUILDING),
      switchMap(({ payload }) => payload ? from(getVenuesByBuilding(payload.building.id)) : of([])),
      flatMap(buildings => [setSelectedVenue(null), fetchVenuesFulfilled(buildings)]),
      catchError(error => of(fetchVenuesErred(error))),
    )

export default combineEpics(
  resetVenuesEpic,
  fetchVenuesEpic,
)
