import { combineEpics } from 'redux-observable'
import establishments from '../modules/establishments/establishments.epics'
import buildings from '../modules/buildings/buildings.epics'
import venues from '../modules/venues/venues.epics'
import rooms from '../modules/rooms/rooms.epics'
import services from '../modules/services/services.epics'
import connections from '../modules/connections/connections.epics'
import waypoints from '../modules/waypoints/waypoints.epics'
import map from '../modules/map/map.epics'

export default combineEpics(
  establishments,
  buildings,
  venues,
  rooms,
  services,
  connections,
  waypoints,
  map,
)
