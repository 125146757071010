import { handleActions } from 'redux-actions'
import {
  FETCH_BUILDINGS_REQUEST,
  FETCH_BUILDINGS_FULFILLED,
  FETCH_BUILDINGS_ERRED,
  SET_SELECTED_BUILDING,
} from './buildings.types'

export const INITIAL_STATE = {
  items: [],
  selected: null,
  error: null,
  isFetching: false,
}

export default handleActions({
  [FETCH_BUILDINGS_REQUEST]: state => ({
    ...state,
    items: [],
    selected: null,
    error: null,
    isFetching: true,
  }),
  [FETCH_BUILDINGS_FULFILLED]: (state, { payload }) => ({
    ...state,
    items: payload,
    error: null,
    isFetching: false,
  }),
  [FETCH_BUILDINGS_ERRED]: (state, { payload }) => ({
    ...state,
    error: payload,
    isFetching: false,
  }),
  [SET_SELECTED_BUILDING]: (state, { payload }) => ({
    ...state,
    selected: payload,
  }),
}, INITIAL_STATE)
