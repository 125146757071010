import { createAction } from 'redux-actions'
import {
  FETCH_SERVICES_REQUEST,
  FETCH_SERVICES_FULFILLED,
  FETCH_SERVICES_ERRED,
  CREATE_SERVICE_REQUEST,
  CREATE_SERVICE_FULFILLED,
  CREATE_SERVICE_ERRED,
  DELETE_SERVICE_REQUEST,
  DELETE_SERVICE_FULFILLED,
  DELETE_SERVICE_ERRED,
} from './services.types'

export const fetchServices = createAction(FETCH_SERVICES_REQUEST)
export const fetchServicesFulfilled = createAction(FETCH_SERVICES_FULFILLED)
export const fetchServicesErred = createAction(FETCH_SERVICES_ERRED)
export const createService = createAction(CREATE_SERVICE_REQUEST)
export const createServiceFulfilled = createAction(CREATE_SERVICE_FULFILLED)
export const createServiceErred = createAction(CREATE_SERVICE_ERRED)
export const deleteService = createAction(DELETE_SERVICE_REQUEST)
export const deleteServiceFulfilled = createAction(DELETE_SERVICE_FULFILLED)
export const deleteServiceErred = createAction(DELETE_SERVICE_ERRED)
