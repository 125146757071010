import { handleActions } from 'redux-actions'
import {
  FETCH_ROOMS_REQUEST,
  FETCH_ROOMS_FULFILLED,
  FETCH_ROOMS_ERRED,
  SET_SELECTED_ROOM,
  SET_HOVERED_ROOM,
  SAVE_ROOM_FULFILLED,
  SAVE_ROOM_ERRED,
  OPEN_ROOM_DIALOG,
  CLOSE_ROOM_DIALOG,
} from './rooms.types'

export const INITIAL_STATE = {
  items: [],
  selected: null,
  hovered: null,
  error: null,
  isFetching: false,
  dialog: false,
}

export default handleActions({
  [FETCH_ROOMS_REQUEST]: state => ({
    ...state,
    items: [],
    selected: null,
    hovered: null,
    error: null,
    isFetching: true,
  }),
  [FETCH_ROOMS_FULFILLED]: (state, { payload }) => ({
    ...state,
    items: payload,
    error: null,
    isFetching: false,
  }),
  [FETCH_ROOMS_ERRED]: (state, { payload }) => ({
    ...state,
    error: payload,
    isFetching: false,
  }),
  [SET_SELECTED_ROOM]: (state, { payload }) => ({
    ...state,
    selected: payload,
  }),
  [SET_HOVERED_ROOM]: (state, { payload }) => ({
    ...state,
    hovered: payload,
  }),
  [SAVE_ROOM_FULFILLED]: (state, { payload }) => ({
    ...state,
    items: [...state.items.filter(r => r.room.id !== payload.room.id), payload],
  }),
  [SAVE_ROOM_ERRED]: (state, { payload }) => ({
    ...state,
    error: payload,
  }),
  [OPEN_ROOM_DIALOG]: state => ({
    ...state,
    dialog: true,
  }),
  [CLOSE_ROOM_DIALOG]: state => ({
    ...state,
    dialog: false,
  }),
}, INITIAL_STATE)
