import { handleActions } from 'redux-actions'
import {
  FETCH_CONNECTIONS_REQUEST,
  FETCH_CONNECTIONS_FULFILLED,
  FETCH_CONNECTIONS_ERRED,
  SET_SELECTED_CONNECTION,
  SET_HOVERED_CONNECTION,
  CONNECT_WAYPOINTS_FULFILLED,
  CONNECT_WAYPOINTS_ERRED,
  DELETE_CONNECTION_FULFILLED,
  DELETE_CONNECTION_ERRED,
} from './connections.types'
import { SAVE_WAYPOINT_FULFILLED } from '../waypoints/waypoints.types'

export const INITIAL_STATE = {
  items: [],
  selected: null,
  hovered: null,
  error: null,
  isFetching: false,
}

export default handleActions({
  [FETCH_CONNECTIONS_REQUEST]: state => ({
    ...state,
    items: [],
    selected: null,
    hovered: null,
    error: null,
    isFetching: true,
  }),
  [FETCH_CONNECTIONS_FULFILLED]: (state, { payload }) => ({
    ...state,
    items: payload,
    error: null,
    isFetching: false,
  }),
  [FETCH_CONNECTIONS_ERRED]: (state, { payload }) => ({
    ...state,
    error: payload,
    isFetching: false,
  }),
  [SET_SELECTED_CONNECTION]: (state, { payload }) => ({
    ...state,
    selected: payload,
  }),
  [SET_HOVERED_CONNECTION]: (state, { payload }) => ({
    ...state,
    hovered: payload,
  }),
  [CONNECT_WAYPOINTS_FULFILLED]: (state, { payload }) => ({
    ...state,
    items: [...state.items, payload],
  }),
  [CONNECT_WAYPOINTS_ERRED]: (state, { payload }) => ({
    ...state,
    error: payload,
  }),
  [DELETE_CONNECTION_FULFILLED]: (state, { payload }) => ({
    ...state,
    items: state.items.filter(c => c.id !== payload.connectionId),
  }),
  [DELETE_CONNECTION_ERRED]: (state, { payload }) => ({
    ...state,
    error: payload,
  }),
  // when we drag a waypoint, we want to update corresponding connections, if any
  [SAVE_WAYPOINT_FULFILLED]: (state, { payload }) => ({
    ...state,
    items: state.items.map(connection => connection.start.id === payload.id ? ({ ...connection, start: payload }) :
      connection.end.id === payload.id ? ({ ...connection, end: payload }) :
        connection,
    ),
  }),
}, INITIAL_STATE)
