import { Component } from 'react'
import { createPortal } from 'react-dom'
import { MAP } from 'react-google-maps-api'
import { number, string, element, object, array, oneOfType } from 'prop-types'

/**
 * This Component for add custom control to map
 * (map.controls[position].push(component))
 * NOTE:
 * Can ref to map through context in constructor (or this.context expect constructor)
 * User constructor to add div and render will createPortal
 */
export default class MapControl extends Component {
  static propTypes = {
    position: number.isRequired,
    children: oneOfType([element, array]),
    className: string,
  }

  static defaultProps = {
    children: [],
    className: '',
  }

  static contextTypes = { [MAP]: object }

  constructor(props) {
    super(props)

    this.map = props.map.state.map
    this.controlDiv = document.createElement('div')
    props.className && this.controlDiv.classList.add(props.className)
    this.controlDiv.style.padding = props.padding
    this.divIndex = this.map.controls[this.props.position].length
    this.map.controls[props.position].push(this.controlDiv)
  }

  componentWillUnmount() {
    this.map.controls[this.props.position].removeAt(this.divIndex)
  }

  render() {
    return createPortal(this.props.children, this.controlDiv)
  }
}
