import React from 'react'
import { connect } from 'react-redux'
import { GroundOverlay } from 'react-google-maps-api'
import { getVenueBounds } from './venues.utils'

const VenueSelection = ({ selectedVenue }) => selectedVenue &&
  <GroundOverlay key={selectedVenue.venue.id}
                 url={selectedVenue.venue.image}
                 bounds={getVenueBounds(selectedVenue)}
                 overlay={false}/>

export default connect(store => ({
  selectedVenue: store.venues.selected,
}), {})(VenueSelection)
