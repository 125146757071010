import React from 'react'
import CCLogo from '../../assets/images/classcompass.svg'

const styles = {
  logo: {
    height: 30,
    zIndex: 2,
  },
}

const Logo = () => <img src={CCLogo} alt={'ClassCompass'} style={styles.logo}/>

export default Logo
