import { handleActions } from 'redux-actions'
import { SET_INSTANCE } from './map.types'

export const INITIAL_STATE = {
  instance: null,
  initialRegion: {
    latitude: 45.5154338,
    latitudeDelta: 0.35,
    longitude: -73.7149646,
    longitudeDelta: 0.35,
  },
}

export default handleActions({
  [SET_INSTANCE]: (state, action) => ({
    ...state,
    instance: action.payload,
  }),
}, INITIAL_STATE)
