export const FETCH_ROOMS_REQUEST = 'FETCH_ROOMS_REQUEST'
export const FETCH_ROOMS_FULFILLED = 'FETCH_ROOMS_FULFILLED'
export const FETCH_ROOMS_ERRED = 'FETCH_ROOMS_ERRED'
export const SET_SELECTED_ROOM = 'SET_SELECTED_ROOM'
export const SET_HOVERED_ROOM = 'SET_HOVERED_ROOM'
export const SAVE_ROOM = 'SAVE_ROOM'
export const SAVE_ROOM_FULFILLED = 'SAVE_ROOM_FULFILLED'
export const SAVE_ROOM_ERRED = 'SAVE_ROOM_ERRED'
export const OPEN_ROOM_DIALOG = 'OPEN_ROOM_DIALOG'
export const CLOSE_ROOM_DIALOG = 'CLOSE_ROOM_DIALOG'
