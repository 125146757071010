import React from 'react'
import { LoadScript, GoogleMap } from 'react-google-maps-api'
import { connect } from 'react-redux'
import MapControl from './MapControl'
import { ControlPosition } from './map.control-positions'
import { googleMapsKey } from '../../constants/Config'
import EstablishmentBuildingSelection from './EstablishmentBuildingSelection'
import VenueSelection from '../venues/VenueSelection'
import VenueOverlay from '../venues/VenueOverlay'
import Connections from '../connections/Connections'
import Waypoints from '../waypoints/Waypoints'
import Rooms from '../rooms/Rooms'
import Logo from '../logo/Logo'
import { setInstance } from './map.actions'

const mapStyle = [
  { stylers: [{ visibility: 'off' }] },
  { featureType: 'administrative', stylers: [{ visibility: 'off' }] },
  { featureType: 'landscape', stylers: [{ visibility: 'on' }] },
  { featureType: 'landscape', elementType: 'labels', stylers: [{ visibility: 'off' }] },
  { featureType: 'poi', stylers: [{ visibility: 'off' }] },
  { featureType: 'road', stylers: [{ visibility: 'on' }] },
  { featureType: 'transit', stylers: [{ visibility: 'off' }] },
  { featureType: 'water', stylers: [{ visibility: 'on' }] },
]

const options = {
  mapTypeControl: false,
  fullscreenControl: false,
  streetViewControl: false,
  zoomControl: false,
  styles: mapStyle,
}

const GoogleMapComponent = ({ venues, instance, setInstance }) =>
  <LoadScript
    googleMapsApiKey={googleMapsKey}
    language={'en'}
    region={'EN'}
    version={'weekly'}
    libraries={[]}
    loadingElement={<div>Loading...</div>}>
    <GoogleMap
      ref={setInstance}
      options={options}
      zoom={8}
      center={{ lat: 45.5154338, lng: -73.7149646 }}
      mapContainerStyle={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}>

      <MapControl position={ControlPosition.TOP_LEFT} padding={'15px 0 0 15px'} map={instance}>
        <Logo/>
      </MapControl>

      <MapControl position={ControlPosition.TOP_RIGHT} padding={'15px 15px 0 0'} map={instance}>
        <EstablishmentBuildingSelection/>
      </MapControl>

      {
        venues.length ?
          <MapControl position={ControlPosition.RIGHT_CENTER} padding={'0 15px 0 0'} map={instance}>
            <VenueSelection/>
          </MapControl> : null
      }

      <VenueOverlay/>
      <Connections/>
      <Waypoints/>
      <Rooms/>

    </GoogleMap>,
  </LoadScript>

export default connect(store => ({
  instance: store.map.instance,
  venues: store.venues.items,
}), { setInstance })(GoogleMapComponent)
