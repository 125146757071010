import { map, tap, filter, withLatestFrom } from 'rxjs/operators'
import { ofType, combineEpics } from 'redux-observable'
import { SET_SELECTED_VENUE } from '../venues/venues.types'
import { getVenueBounds } from '../venues/venues.utils'
import { SET_INSTANCE } from './map.types'

const setControlsEpic = action$ =>
  action$
    .pipe(
      ofType(SET_INSTANCE),
      map(() => ({ type: 'VOID' })),
    )

const focusVenueEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(SET_SELECTED_VENUE),
      filter(({ payload }) => payload),
      withLatestFrom(state$),
      tap(([{ payload }, state]) => state.map.instance.fitBounds(getVenueBounds(payload))),
      map(() => ({ type: 'VOID' })),
    )

export default combineEpics(
  setControlsEpic,
  focusVenueEpic,
)
