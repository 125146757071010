import React from 'react'
import { connect } from 'react-redux'
import { Marker } from 'react-google-maps-api'
import { setSelectedWaypoint, setHoveredWaypoint, saveWaypoint } from './waypoints.actions'
import Colors from '../../constants/Colors'

const isSelectedWaypoint = (selectedWaypoint, waypoint) => selectedWaypoint && selectedWaypoint.id === waypoint.id
const isHoveredWaypoint = (hoveredWaypoint, waypoint) => hoveredWaypoint && hoveredWaypoint.id === waypoint.id

const Waypoints = ({ selectedWaypoint, hoveredWaypoint, waypoints, setSelectedWaypoint, setHoveredWaypoint, saveWaypoint }) =>
  waypoints.map(w =>
    <Marker
      key={w.id}
      position={{ lat: w.latitude, lng: w.longitude }}
      clickable={true}
      draggable={true}
      onClick={() => setSelectedWaypoint(w)}
      onMouseOver={() => setHoveredWaypoint(w)}
      onMouseOut={() => setHoveredWaypoint(null)}
      onDragEnd={e => saveWaypoint({ id: w.id, latitude: e.latLng.lat(), longitude: e.latLng.lng() })}
      icon={{
        path: window.google && window.google.maps.SymbolPath.CIRCLE,
        strokeColor: isSelectedWaypoint(selectedWaypoint, w) || isHoveredWaypoint(hoveredWaypoint, w) ? Colors.secondary : Colors.primary,
        fillOpacity: 1,
        scale: 2,
      }}
    />,
  )

export default connect(store => ({
  waypoints: store.waypoints.items,
  selectedWaypoint: store.waypoints.selected,
  hoveredWaypoint: store.waypoints.hovered,
}), { setSelectedWaypoint, setHoveredWaypoint, saveWaypoint })(Waypoints)
