import React from 'react'
import { connect } from 'react-redux'
import { Marker } from 'react-google-maps-api'
import { setSelectedRoom, setHoveredRoom, saveRoom, openRoomDialog } from './rooms.actions'
import Colors from '../../constants/Colors'

const isSelectedRoom = (selectedRoom, room) => selectedRoom && selectedRoom.room.id === room.room.id
const isHoveredRoom = (hoveredRoom, room) => hoveredRoom && hoveredRoom.room.id === room.room.id

const Rooms = ({ selectedRoom, hoveredRoom, rooms, setSelectedRoom, setHoveredRoom, saveRoom, openRoomDialog }) =>
  rooms.map(r =>
    <Marker
      key={r.room.id}
      position={{ lat: r.waypoint.latitude, lng: r.waypoint.longitude }}
      clickable={true}
      draggable={true}
      onClick={() => setSelectedRoom(r)}
      onDblClick={openRoomDialog}
      onMouseOver={() => setHoveredRoom(r)}
      onMouseOut={() => setHoveredRoom(null)}
      onDragEnd={e => saveRoom({
        room: r.room,
        waypoint: { id: r.waypoint.id, latitude: e.latLng.lat(), longitude: e.latLng.lng() },
      })}
      label={{
        text: r.room.code,
        fontSize: '12px',
        color: isSelectedRoom(selectedRoom, r) || isHoveredRoom(hoveredRoom, r) ? Colors.secondary : Colors.primary,
      }}
      icon={{
        path: window.google && window.google.maps.SymbolPath.CIRCLE,
        strokeOpacity: 0,
        scale: 5,
      }}
    />,
  )

export default connect(store => ({
  rooms: store.rooms.items,
  selectedRoom: store.rooms.selected,
  hoveredRoom: store.rooms.hovered,
}), { setSelectedRoom, setHoveredRoom, saveRoom, openRoomDialog })(Rooms)
