import { of, from } from 'rxjs/index'
import { map, flatMap, switchMap, catchError } from 'rxjs/operators'
import { ofType, combineEpics } from 'redux-observable'
import { getWaypointsByVenue, saveWaypoint } from './waypoints.service'
import {
  setSelectedWaypoint,
  fetchWaypointsFulfilled,
  fetchWaypointsErred,
  setSelectedWaypointFulfilled,
  saveWaypointFulfilled,
  saveWaypointErred,
} from './waypoints.actions'
import { SAVE_WAYPOINT, SET_SELECTED_WAYPOINT } from './waypoints.types'
import { SET_SELECTED_VENUE } from '../venues/venues.types'

const resetWaypointsEpic = action$ =>
  action$
    .pipe(
      ofType(SET_SELECTED_VENUE),
      flatMap(() => [fetchWaypointsFulfilled([]), setSelectedWaypoint(null)]),
    )

const setSelectedWaypointEpic = action$ =>
  action$
    .pipe(
      ofType(SET_SELECTED_WAYPOINT),
      map(({ payload }) => setSelectedWaypointFulfilled(payload)),
      catchError(error => of(fetchWaypointsErred(error))),
    )

const fetchWaypointsEpic = action$ =>
  action$
    .pipe(
      ofType(SET_SELECTED_VENUE),
      switchMap(({ payload }) => payload ? from(getWaypointsByVenue(payload.venue.id)) : of([])),
      flatMap(venues => [setSelectedWaypoint(null), fetchWaypointsFulfilled(venues)]),
      catchError(error => of(fetchWaypointsErred(error))),
    )

const saveWaypointEpic = action$ =>
  action$
    .pipe(
      ofType(SAVE_WAYPOINT),
      switchMap(({ payload }) => from(saveWaypoint(payload))),
      map(saveWaypointFulfilled),
      catchError(error => of(saveWaypointErred(error))),
    )

export default combineEpics(
  resetWaypointsEpic,
  setSelectedWaypointEpic,
  fetchWaypointsEpic,
  saveWaypointEpic,
)
