import { of, from } from 'rxjs/index'
import { flatMap, switchMap, catchError, map } from 'rxjs/operators'
import { ofType, combineEpics } from 'redux-observable'
import { getRoomsByVenue } from './rooms.service'
import { saveWaypoint } from '../waypoints/waypoints.service'
import {
  setSelectedRoom,
  fetchRoomsFulfilled,
  fetchRoomsErred,
  saveRoomFulfilled,
  saveRoomErred,
} from './rooms.actions'
import { SAVE_ROOM } from './rooms.types'
import { SET_SELECTED_VENUE } from '../venues/venues.types'

const resetRoomsEpic = action$ =>
  action$
    .pipe(
      ofType(SET_SELECTED_VENUE),
      flatMap(() => [fetchRoomsFulfilled([]), setSelectedRoom(null)]),
    )

const fetchRoomsEpic = action$ =>
  action$
    .pipe(
      ofType(SET_SELECTED_VENUE),
      switchMap(({ payload }) => payload ? from(getRoomsByVenue(payload.venue.id)) : of([])),
      flatMap(venues => [setSelectedRoom(null), fetchRoomsFulfilled(venues)]),
      catchError(error => of(fetchRoomsErred(error))),
    )

const saveRoomEpic = action$ =>
  action$
    .pipe(
      ofType(SAVE_ROOM),
      switchMap(({ payload: { room, waypoint } }) => from(saveWaypoint(waypoint).then(waypoint => ({
        room,
        waypoint,
      })))),
      map(saveRoomFulfilled),
      catchError(error => of(saveRoomErred(error))),
    )

export default combineEpics(
  resetRoomsEpic,
  fetchRoomsEpic,
  saveRoomEpic,
)
