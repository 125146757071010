import { createAction } from 'redux-actions'
import {
  FETCH_ROOMS_REQUEST,
  FETCH_ROOMS_FULFILLED,
  FETCH_ROOMS_ERRED,
  SET_SELECTED_ROOM,
  SET_HOVERED_ROOM,
  SAVE_ROOM,
  SAVE_ROOM_FULFILLED,
  SAVE_ROOM_ERRED,
  OPEN_ROOM_DIALOG,
  CLOSE_ROOM_DIALOG,
} from './rooms.types'

export const fetchRooms = createAction(FETCH_ROOMS_REQUEST)
export const fetchRoomsFulfilled = createAction(FETCH_ROOMS_FULFILLED)
export const fetchRoomsErred = createAction(FETCH_ROOMS_ERRED)
export const setSelectedRoom = createAction(SET_SELECTED_ROOM)
export const setHoveredRoom = createAction(SET_HOVERED_ROOM)
export const saveRoom = createAction(SAVE_ROOM)
export const saveRoomFulfilled = createAction(SAVE_ROOM_FULFILLED)
export const saveRoomErred = createAction(SAVE_ROOM_ERRED)
export const openRoomDialog = createAction(OPEN_ROOM_DIALOG)
export const closeRoomDialog = createAction(CLOSE_ROOM_DIALOG)
