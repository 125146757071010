import React from 'react'
import { connect } from 'react-redux'
import { Form, withFormik } from 'formik'
import urlRegex from 'url-regex'
import { Button, Grid, TextField } from '@material-ui/core'
import { fetchServices, createService } from './services.actions'
import Colors from '../../constants/Colors'

const ErrorMessage = ({ message }) => <i style={{ color: Colors.error, fontSize: 10 }}>{message}</i>

const CreateServiceForm = ({ values, errors, touched, handleBlur, handleChange, handleSubmit }) =>
  <Form onSubmit={handleSubmit} noValidate>
    <Grid container spacing={8}>
      <Grid item xs={6}>
        <TextField
          name={'name'}
          label={'Name'}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          variant={'outlined'}
          margin={'dense'}
          error={!!errors.name}
          fullWidth
        />
        <ErrorMessage message={errors.name}/>
      </Grid>

      <Grid item xs={6}>
        <TextField
          name={'url'}
          label={'Url'}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.url}
          variant={'outlined'}
          margin={'dense'}
          error={!!errors.url}
          fullWidth
        />
        <ErrorMessage message={errors.url}/>

      </Grid>
      <Grid item xs={12}>
        <Button variant={'outlined'} color={'primary'} disabled={!values.roomId} type={'submit'} fullWidth>
          + Create Service
        </Button>
      </Grid>
    </Grid>
  </Form>

const enhanced = withFormik({
  mapPropsToValues: ({ id, selectedRoom, createService }) => ({
    id,
    name: '',
    url: '',
    roomId: selectedRoom && selectedRoom.room.id,
    createService,
  }),

  validate: values => {
    const errors = {}

    if (!values.name) {
      errors.name = 'Name is Required'
    }

    if (!values.url) {
      errors.url = 'URL is Required'
    } else if (!urlRegex({ strict: true, exact: true }).test(values.url)) {
      errors.url = 'URL is Invalid'
    }

    if (!values.roomId) {
      errors.name = 'Room Id is Required'
    }

    return errors
  },

  handleSubmit: (values, { setSubmitting, resetForm }) => {
    const service = { id: values.id, name: values.name, url: values.url }
    const roomId = values.roomId
    const createService = values.createService

    return createService({ service, roomId, resetForm, setSubmitting })
  },

  displayName: 'CreateServiceForm',
})(CreateServiceForm)

export default connect(store => ({
  services: store.services.items,
  selectedRoom: store.rooms.selected,
}), { fetchServices, createService })(enhanced)
