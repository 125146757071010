import { createAction } from 'redux-actions'
import {
  FETCH_WAYPOINTS_REQUEST,
  FETCH_WAYPOINTS_FULFILLED,
  FETCH_WAYPOINTS_ERRED,
  SET_SELECTED_WAYPOINT,
  SET_SELECTED_WAYPOINT_FULFILLED,
  SET_HOVERED_WAYPOINT,
  SAVE_WAYPOINT,
  SAVE_WAYPOINT_FULFILLED,
  SAVE_WAYPOINT_ERRED,
} from './waypoints.types'

export const fetchWaypoints = createAction(FETCH_WAYPOINTS_REQUEST)
export const fetchWaypointsFulfilled = createAction(FETCH_WAYPOINTS_FULFILLED)
export const fetchWaypointsErred = createAction(FETCH_WAYPOINTS_ERRED)
export const setSelectedWaypoint = createAction(SET_SELECTED_WAYPOINT)
export const setSelectedWaypointFulfilled = createAction(SET_SELECTED_WAYPOINT_FULFILLED)
export const setHoveredWaypoint = createAction(SET_HOVERED_WAYPOINT)
export const saveWaypoint = createAction(SAVE_WAYPOINT)
export const saveWaypointFulfilled = createAction(SAVE_WAYPOINT_FULFILLED)
export const saveWaypointErred = createAction(SAVE_WAYPOINT_ERRED)
