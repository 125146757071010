import { createAction } from 'redux-actions'
import {
  FETCH_BUILDINGS_REQUEST,
  FETCH_BUILDINGS_FULFILLED,
  FETCH_BUILDINGS_ERRED,
  SET_SELECTED_BUILDING,
  SELECT_NEAREST_BUILDING,
} from './buildings.types'

export const fetchBuildings = createAction(FETCH_BUILDINGS_REQUEST)
export const fetchBuildingsFulfilled = createAction(FETCH_BUILDINGS_FULFILLED)
export const fetchBuildingsErred = createAction(FETCH_BUILDINGS_ERRED)
export const setSelectedBuilding = createAction(SET_SELECTED_BUILDING)
export const selectNearestBuilding = createAction(SELECT_NEAREST_BUILDING)
