import React from 'react'
import { connect } from 'react-redux'
import { Polyline } from 'react-google-maps-api'
import { splitConnection, setSelectedConnection, setHoveredConnection } from './connections.actions'
import Colors from '../../constants/Colors'

const isSelectedConnection = (selectedConnection, connection) => selectedConnection && selectedConnection.id === connection.id
const isHoveredConnection = (hoveredConnection, connection) => hoveredConnection && hoveredConnection.id === connection.id

const Connections = ({ selectedConnection, hoveredConnection, connections, splitConnection, setSelectedConnection, setHoveredConnection }) =>
  connections.map(c =>
    <Polyline
      key={c.id}
      clickable={true}
      onClick={e => splitConnection({
        connection: c,
        waypoint: { latitude: e.latLng.lat(), longitude: e.latLng.lng() },
      })}
      onMouseOver={() => setHoveredConnection(c)}
      onMouseOut={() => setHoveredConnection(null)}
      path={[
        { lat: c.start.latitude, lng: c.start.longitude },
        { lat: c.end.latitude, lng: c.end.longitude },
      ]}
      options={{
        strokeColor: isSelectedConnection(selectedConnection, c) || isHoveredConnection(hoveredConnection, c) ? Colors.secondary : Colors.primary,
        strokeOpacity: 1.0,
        strokeWeight: 2,
      }}
    />,
  )

export default connect(store => ({
  connections: store.connections.items,
  selectedConnection: store.connections.selected,
  hoveredConnection: store.connections.hovered,
}), { splitConnection, setSelectedConnection, setHoveredConnection })(Connections)
