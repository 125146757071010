import { createStore, applyMiddleware } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import reducers from './reducers'
import epics from './epics'

const middlewares = createEpicMiddleware()

const store = createStore(
  reducers,
  {},
  applyMiddleware(middlewares),
)

middlewares.run(epics)

export default store
