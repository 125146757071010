import { of, from } from 'rxjs/index'
import { map, flatMap, switchMap, catchError } from 'rxjs/operators'
import { ofType, combineEpics } from 'redux-observable'
import { SET_SELECTED_ESTABLISHMENT } from '../establishments/establishments.types'
import { setSelectedBuilding, fetchBuildingsFulfilled, fetchBuildingsErred } from './buildings.actions'
import { getBuildingsByEstablishment } from './buildings.service'

const resetBuildingsEpic = action$ =>
  action$
    .pipe(
      ofType(SET_SELECTED_ESTABLISHMENT),
      flatMap(() => [fetchBuildingsFulfilled([]), setSelectedBuilding(null)]),
    )

const fetchBuildingsEpic = action$ =>
  action$
    .pipe(
      ofType(SET_SELECTED_ESTABLISHMENT),
      switchMap(({ payload }) => payload ? from(getBuildingsByEstablishment(payload.establishment.id)) : of([])),
      map(fetchBuildingsFulfilled),
      catchError(error => of(fetchBuildingsErred(error))),
    )

export default combineEpics(
  resetBuildingsEpic,
  fetchBuildingsEpic,
)
