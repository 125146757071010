import { of, from } from 'rxjs/index'
import { map, tap, filter, flatMap, switchMap, catchError } from 'rxjs/operators'
import { ofType, combineEpics } from 'redux-observable'
import { saveWaypoint } from '../waypoints/waypoints.service'
import { getConnectionsByVenue, connectWaypoints, deleteConnectionById } from './connections.service'
import {
  setSelectedConnection,
  fetchConnectionsFulfilled,
  fetchConnectionsErred,
  connectWaypointsFulfilled,
  connectWaypointsErred,
  deleteConnectionFulfilled,
  deleteConnectionErred,
} from './connections.actions'
import { saveWaypointFulfilled, setSelectedWaypointFulfilled } from '../waypoints/waypoints.actions'
import { SPLIT_CONNECTION, DELETE_CONNECTION } from './connections.types'
import { SET_SELECTED_VENUE } from '../venues/venues.types'
import { SET_SELECTED_WAYPOINT } from '../waypoints/waypoints.types'

const resetConnectionsEpic = action$ =>
  action$
    .pipe(
      ofType(SET_SELECTED_VENUE),
      flatMap(() => [fetchConnectionsFulfilled([]), setSelectedConnection(null)]),
    )

const fetchConnectionsEpic = action$ =>
  action$
    .pipe(
      ofType(SET_SELECTED_VENUE),
      switchMap(({ payload }) => payload ? from(getConnectionsByVenue(payload.venue.id)) : of([])),
      flatMap(connections => [setSelectedConnection(null), fetchConnectionsFulfilled(connections)]),
      catchError(error => of(fetchConnectionsErred(error))),
    )

const connectWaypointsEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(SET_SELECTED_WAYPOINT),
      filter(({ payload }) => payload && state$.value.waypoints.selected && payload.id !== state$.value.waypoints.selected.id),
      tap(({ payload }) => console.log(state$.value.waypoints.selected, payload)),
      switchMap(({ payload }) => from(connectWaypoints(state$.value.waypoints.selected.id, payload.id)
        .then(connection => ({ connection, waypoint: payload })))),
      flatMap(response => [setSelectedWaypointFulfilled(response.waypoint), connectWaypointsFulfilled(response.connection)]),
      catchError(error => of(connectWaypointsErred(error))),
    )

const splitConnectionEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(SPLIT_CONNECTION),
      filter(() => state$.value.venues.selected),
      switchMap(({ payload }) => from(
        deleteConnectionById(payload.connection.id)
          .then(deleted => saveWaypoint(payload.waypoint, state$.value.venues.selected.venue.id)
            .then(waypoint => connectWaypoints(payload.connection.start.id, waypoint.id)
              .then(connectionStartToWaypoint => connectWaypoints(waypoint.id, payload.connection.end.id)
                .then(connectionWaypointToEnd => ({
                  deleted,
                  waypoint,
                  connectionStartToWaypoint,
                  connectionWaypointToEnd,
                })),
              ),
            ),
          ),
      )),
      flatMap(response => [
        deleteConnectionFulfilled(response.deleted),
        saveWaypointFulfilled(response.waypoint),
        connectWaypointsFulfilled(response.connectionStartToWaypoint),
        connectWaypointsFulfilled(response.connectionWaypointToEnd),
      ]),
      catchError(error => of(fetchConnectionsErred(error))),
    )

const deleteConnectionEpic = action$ =>
  action$
    .pipe(
      ofType(DELETE_CONNECTION),
      switchMap(({ payload }) => from(deleteConnectionById(payload.id))),
      map(deleteConnectionFulfilled),
      catchError(error => of(deleteConnectionErred(error))),
    )

export default combineEpics(
  resetConnectionsEpic,
  fetchConnectionsEpic,
  connectWaypointsEpic,
  splitConnectionEpic,
  deleteConnectionEpic,
)
