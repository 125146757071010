import { combineReducers } from 'redux'
import establishments from '../modules/establishments/establishments.reducers'
import buildings from '../modules/buildings/buildings.reducers'
import venues from '../modules/venues/venues.reducers'
import rooms from '../modules/rooms/rooms.reducers'
import services from '../modules/services/services.reducers'
import connections from '../modules/connections/connections.reducers'
import waypoints from '../modules/waypoints/waypoints.reducers'
import map from '../modules/map/map.reducers'

export default combineReducers({
  establishments,
  buildings,
  venues,
  rooms,
  services,
  connections,
  waypoints,
  map,
})
