import { handleActions } from 'redux-actions'
import {
  FETCH_WAYPOINTS_REQUEST,
  FETCH_WAYPOINTS_FULFILLED,
  FETCH_WAYPOINTS_ERRED,
  SET_SELECTED_WAYPOINT_FULFILLED,
  SET_HOVERED_WAYPOINT,
  SAVE_WAYPOINT_FULFILLED,
  SAVE_WAYPOINT_ERRED,
} from './waypoints.types'

export const INITIAL_STATE = {
  items: [],
  selected: null,
  hovered: null,
  error: null,
  isFetching: false,
}

export default handleActions({
  [FETCH_WAYPOINTS_REQUEST]: state => ({
    ...state,
    items: [],
    selected: null,
    hovered: null,
    error: null,
    isFetching: true,
  }),
  [FETCH_WAYPOINTS_FULFILLED]: (state, { payload }) => ({
    ...state,
    items: payload,
    error: null,
    isFetching: false,
  }),
  [FETCH_WAYPOINTS_ERRED]: (state, { payload }) => ({
    ...state,
    error: payload,
    isFetching: false,
  }),
  [SET_SELECTED_WAYPOINT_FULFILLED]: (state, { payload }) => ({
    ...state,
    selected: payload,
  }),
  [SET_HOVERED_WAYPOINT]: (state, { payload }) => ({
    ...state,
    hovered: payload,
  }),
  [SAVE_WAYPOINT_FULFILLED]: (state, { payload }) => ({
    ...state,
    items: [...state.items.filter(w => w.id !== payload.id), payload],
  }),
  [SAVE_WAYPOINT_ERRED]: (state, { payload }) => ({
    ...state,
    error: payload,
  }),
}, INITIAL_STATE)
