import React from 'react'
import { connect } from 'react-redux'
import lifecycle from '@hocs/with-lifecycle'
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { fetchServices, deleteService } from './services.actions'

const styles = {
  list: {
    borderRadius: 4,
  },
  listItem: {
    paddingRight: 0,
  },
}

const ServiceUrl = ({ url }) => <a href={url} target={'_blank'}>{url}</a>

const RoomServicesList = ({ services, deleteService }) =>
  services.length ?
    <List style={styles.list} dense>
      {services.map(serviceResponse =>
        <ListItem key={serviceResponse.service.id} button
                  style={styles.listItem} divider>
          <ListItemText primary={serviceResponse.service.name}
                        secondary={<ServiceUrl url={serviceResponse.service.url}/>}/>
          <ListItemSecondaryAction>
            <IconButton aria-label={'Delete'} onClick={() => deleteService(serviceResponse)}>
              <Delete/>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>,
      )}
    </List> :
    <p><i>No Services</i></p>

const enhanced = lifecycle({
  onDidMount: props => props.fetchServices(props.selectedRoom),
})(RoomServicesList)

export default connect(store => ({
  services: store.services.items,
  selectedRoom: store.rooms.selected,
}), { fetchServices, deleteService })(enhanced)
