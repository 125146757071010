import React from 'react'
import { connect } from 'react-redux'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import lifecycle from '@hocs/with-lifecycle'
import { fetchEstablishments, setSelectedEstablishment } from './establishments.actions'

const styles = {
  formControl: {
    minWidth: 150,
  },
}

const EstablishmentSelection = ({ establishments, selectedEstablishment, setSelectedEstablishment }) =>
  <FormControl style={styles.formControl}>
    <InputLabel htmlFor="establishment">Establishment</InputLabel>

    <Select autoWidth
            value={selectedEstablishment || ''}
            onChange={e => setSelectedEstablishment(e.target.value)}>

      {establishments.map(establishmentResponse =>
        <MenuItem key={establishmentResponse.establishment.id} value={establishmentResponse}>{establishmentResponse.establishment.name}</MenuItem>,
      )}

    </Select>
  </FormControl>

const enhanced = lifecycle({
  onDidMount: props => props.fetchEstablishments(),
})(EstablishmentSelection)

export default connect(store => ({
  establishments: store.establishments.items,
  selectedEstablishment: store.establishments.selected,
}), { fetchEstablishments, setSelectedEstablishment })(enhanced)
