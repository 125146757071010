import { get, post, delete as del } from 'axios'
import { baseUrl } from '../../constants/Config'

export const getConnectionsByVenue = venueId =>
  get(`${baseUrl}/getConnectionsByVenue`, { params: { venueId } })
    .then(response => response.data)

// INBOUND | OUTBOUND, defaults to BIDIRECTIONAL
export const connectWaypoints = (fromWaypointId, toWaypointId, direction = 'BIDIRECTIONAL') =>
  post(`${baseUrl}/connectWaypoints`, { fromWaypointId, toWaypointId, direction })
    .then(response => response.data)

export const deleteConnectionById = id =>
  del(`${baseUrl}/deleteConnectionById`, { params: { id } })
    .then(response => response.data)
