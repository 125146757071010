import React, { Component } from 'react'
import { Provider } from 'react-redux'
import MapLayout from './modules/map/GoogleMap'
import RoomDialog from './modules/rooms/RoomDialog'
import store from './redux/store'

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <MapLayout/>
        <RoomDialog/>
      </Provider>
    )
  }
}

export default App
