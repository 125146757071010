import { of, from } from 'rxjs/index'
import { map, switchMap, catchError } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { getEstablishments } from './establishments.service'
import { fetchEstablishmentsFulfilled, fetchEstablishmentsErred } from './establishments.actions'
import { FETCH_ESTABLISHMENTS_REQUEST } from './establishments.types'

const fetchEstablishmentsEpic = action$ =>
  action$
    .pipe(
      ofType(FETCH_ESTABLISHMENTS_REQUEST),
      switchMap(() => from(getEstablishments())),
      map(fetchEstablishmentsFulfilled),
      catchError(error => of(fetchEstablishmentsErred(error))),
    )

export default fetchEstablishmentsEpic
