import { of, from } from 'rxjs/index'
import { map, switchMap, catchError } from 'rxjs/operators'
import { ofType, combineEpics } from 'redux-observable'
import { getServicesByRoom, createService, deleteServiceById } from './services.service'
import {
  fetchServicesFulfilled,
  fetchServicesErred,
  createServiceFulfilled,
  createServiceErred,
  deleteServiceFulfilled,
  deleteServiceErred,
} from './services.actions'
import { FETCH_SERVICES_REQUEST, CREATE_SERVICE_REQUEST, DELETE_SERVICE_REQUEST } from '../services/services.types'

const fetchServicesEpic = action$ =>
  action$
    .pipe(
      ofType(FETCH_SERVICES_REQUEST),
      switchMap(({ payload }) => payload ? from(getServicesByRoom(payload.room.id)) : of([])),
      map(fetchServicesFulfilled),
      catchError(error => of(fetchServicesErred(error))),
    )

const createServiceEpic = action$ =>
  action$
    .pipe(
      ofType(CREATE_SERVICE_REQUEST),
      switchMap(({ payload }) => from(createService(payload.service, payload.roomId)
        .then(service => payload.resetForm() || payload.setSubmitting(false) || service))),
      map(createServiceFulfilled),
      catchError(error => of(createServiceErred(error))),
    )

const deleteServiceEpic = action$ =>
  action$
    .pipe(
      ofType(DELETE_SERVICE_REQUEST),
      switchMap(({ payload }) => from(deleteServiceById(payload.service.id))),
      map(deleteServiceFulfilled),
      catchError(error => of(deleteServiceErred(error))),
    )

export default combineEpics(
  fetchServicesEpic,
  createServiceEpic,
  deleteServiceEpic,
)
