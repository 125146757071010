import React from 'react'
import { connect } from 'react-redux'
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import RoomServicesList from '../services/RoomServicesList'
import CreateServiceForm from '../services/CreateServiceForm'
import { closeRoomDialog } from './rooms.actions'

const RoomDialog = ({ selectedRoom, dialog, closeRoomDialog }) =>
  <Dialog open={dialog} onClose={closeRoomDialog} fullWidth>
    <DialogTitle>Room {selectedRoom && selectedRoom.room.code} Services</DialogTitle>
    <DialogContent>
      <RoomServicesList/>
      <CreateServiceForm/>
    </DialogContent>
  </Dialog>

export default connect(({ rooms }) => ({
  dialog: rooms.dialog,
  selectedRoom: rooms.selected,
}), { closeRoomDialog })(RoomDialog)
