import { get, post } from 'axios'
import { baseUrl } from '../../constants/Config'

export const getWaypointsByVenue = venueId =>
  get(`${baseUrl}/getWaypointsByVenue`, { params: { venueId } })
    .then(response => response.data)

export const saveWaypoint = (waypoint, venueId) =>
  post(`${baseUrl}/createWaypoint`, { waypoint, venueId })
    .then(response => response.data)
