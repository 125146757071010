import { handleActions } from 'redux-actions'
import {
  FETCH_ESTABLISHMENTS_REQUEST,
  FETCH_ESTABLISHMENTS_FULFILLED,
  FETCH_ESTABLISHMENTS_ERRED,
  SET_SELECTED_ESTABLISHMENT,
} from './establishments.types'

export const INITIAL_STATE = {
  items: [],
  selected: null,
  error: null,
  isFetching: false,
}

export default handleActions({
  [FETCH_ESTABLISHMENTS_REQUEST]: state => ({
    ...state,
    items: [],
    selected: null,
    error: null,
    isFetching: true,
  }),
  [FETCH_ESTABLISHMENTS_FULFILLED]: (state, { payload }) => ({
    ...state,
    items: payload,
    selected: null,
    error: null,
    isFetching: false,
  }),
  [FETCH_ESTABLISHMENTS_ERRED]: (state, { payload }) => ({
    ...state,
    error: payload,
    isFetching: false,
  }),
  [SET_SELECTED_ESTABLISHMENT]: (state, { payload }) => ({
    ...state,
    selected: payload,
  }),
}, INITIAL_STATE)
