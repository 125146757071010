import React from 'react'
import { connect } from 'react-redux'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { setSelectedBuilding } from './buildings.actions'

const styles = {
  formControl: {
    minWidth: 150,
  },
}

const BuildingSelection = ({ buildings, selectedBuilding, setSelectedBuilding, selectedEstablishment }) =>
  <FormControl disabled={!selectedEstablishment} style={styles.formControl}>
    <InputLabel htmlFor="building">Building</InputLabel>

    <Select autoWidth
            value={selectedBuilding || ''}
            onChange={e => setSelectedBuilding(e.target.value)}>

      {buildings.map(buildingResponse =>
        <MenuItem key={buildingResponse.building.id} value={buildingResponse}>{buildingResponse.building.name}</MenuItem>,
      )}

    </Select>
  </FormControl>

export default connect(store => ({
  buildings: store.buildings.items,
  selectedBuilding: store.buildings.selected,
  selectedEstablishment: store.establishments.selected,
}), { setSelectedBuilding })(BuildingSelection)
