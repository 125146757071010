import { createAction } from 'redux-actions'
import {
  FETCH_ESTABLISHMENTS_REQUEST,
  FETCH_ESTABLISHMENTS_FULFILLED,
  FETCH_ESTABLISHMENTS_ERRED,
  SET_SELECTED_ESTABLISHMENT,
} from './establishments.types'

// use as fetchEstablishmentsFulfilled(ESTABLISHMENTS)
// returns { type: FETCH_ESTABLISHMENTS_FULFILLED, payload: ESTABLISHMENTS }
export const fetchEstablishments = createAction(FETCH_ESTABLISHMENTS_REQUEST)
export const fetchEstablishmentsFulfilled = createAction(FETCH_ESTABLISHMENTS_FULFILLED)
export const fetchEstablishmentsErred = createAction(FETCH_ESTABLISHMENTS_ERRED)
export const setSelectedEstablishment = createAction(SET_SELECTED_ESTABLISHMENT)
