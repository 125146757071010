import React from 'react'
import EstablishmentSelection from '../establishments/EstablishmentSelection'
import BuildingSelection from '../buildings/BuildingSelection'

const styles = {
  container: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
    padding: '5px 10px',
  },
}

const EstablishmentBuildingSelection = () =>
  <div style={styles.container}>
    <EstablishmentSelection/>
    <BuildingSelection/>
  </div>

export default EstablishmentBuildingSelection
